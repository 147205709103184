<template>
  <div class="about login">
    <s-header :showMore="false" />
    <support />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <van-image
            width="100%"
            height="100%"
            fit="contain"
            :src="require('@/assets/images/loginBg.png')"
          />
        </div>
      </div>
      <div class="modular-content">
        <div class="modular-item">
          <van-form @submit="onSubmit">
            <van-field
              v-model="username"
              name="username"
              label="用户名"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
              v-model="password"
              type="password"
              name="password"
              label="密码"
              placeholder="密码"
              :rules="[{ required: true, message: '请填写密码' }]"
            />
            <van-field
              center
              clearable
              label="验证码"
              placeholder="输入验证码"
              v-model="verify"
            >
              <template #button>
                <vue-img-verify ref="verifyRef" />
              </template>
            </van-field>
            <div style="margin: 16px">
              <van-button round block color="#1892d2" native-type="submit"
                >登录</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import sHeader from "@/components/SimpleHeader";
import support from "@/components/Support";
import vueImgVerify from "@/components/VueImageVerify";
import { useRouter } from "vue-router";
import { setLocal, clearLocal } from "@/common/js/utils";
import { login, getUserInfo } from "@/service/user";
// import md5 from 'js-md5'
import { Toast } from "vant";
export default {
  components: {
    vueImgVerify,
    sHeader,
    support,
  },
  mounted() {
    clearLocal();
  },
  setup() {
    const router = useRouter();
    const verifyRef = ref(null);
    const state = reactive({
      username: "",
      password: "",
      type: "login",
      imgCode: "",
      verify: "",
    });

    // 切换登录和注册两种模式
    const toggle = (v) => {
      state.type = v;
      state.verify = "";
    };

    // 提交登录或注册表单
    const onSubmit = async (values) => {
      state.imgCode = verifyRef.value.imgCode || "";
      console.log(verifyRef.value.imgCode);
      if (state.verify.toLowerCase() != state.imgCode.toLowerCase()) {
        Toast.fail("验证码有误");
        return;
      }
      if (state.type == "login") {
        let formData = new FormData();
        formData.append("username", values.username);
        formData.append("password", values.password);

        const apiRes = await login(formData);
        if (apiRes) {
          setLocal("ba-user-token", apiRes.data.token);

          const uiApiRes = await getUserInfo();
          if (uiApiRes) {
            const userInfo = uiApiRes.data.userInfo;
            if (
              userInfo.roleType == "teacher" ||
              userInfo.roleType == "principal"
            ) {
              userInfo.headimg = require("@/assets/images/mine.png");
            } else {
              if (userInfo.sex == 2) {
                userInfo.headimg = require("@/assets/images/girl.jpg");
              } else {
                userInfo.headimg = require("@/assets/images/boy.jpg");
              }
            }
            setLocal("userInfo", JSON.stringify(userInfo));

            if (userInfo.roleType == "student") {
              router.push({ name: "studentIndex" });
            } else if (userInfo.roleType == "teacher") {
              router.push({ name: "teacherIndex" });
            } else if (userInfo.roleType == "principal") {
              router.push({ name: "principalIndex" });
            } else {
              clearLocal();
              Toast.fail("当前账号没有权限");
            }
          } else {
            clearLocal();
          }
        } else {
          clearLocal();
        }
      }
    };

    return {
      ...toRefs(state),
      toggle,
      onSubmit,
      verifyRef,
    };
  },
};
</script>

<style lang="less">
@import "@/common/style/pageContent.less";

.login {
  background-color: #fff;
}
.login .pageContent {
  margin-left: 0;
  background-color: #fff;
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .login .support-footer {
    bottom: 0;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .login .support-footer {
    margin: 0;
    width: 100%;
  }

  .login .modular-base {
    left: 0;
    width: calc(60vw - 20px);
    overflow: hidden;

    .modular-item {
      height: 100%;
    }
  }
  .login .modular-content {
    margin-left: 60vw;

    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
</style>
